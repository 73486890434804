import { Controller } from 'stimulus'

const CLOSED = 'Closed';
const IN_PROGRESS = 'In progress';

export default class extends Controller {
  static targets = [ 'dateStartedSection', 'dateDoneSection', 'dateDoneField', 'dateStartField' ]

  toggleDateStartedSection(event) {
    const selectedValue = event.target.value

    const showDateStarted = selectedValue === CLOSED || selectedValue === IN_PROGRESS;
    const showDateDone = selectedValue === CLOSED;

    this.dateStartedSectionTarget.classList.toggle('d-none', !showDateStarted);
    this.dateDoneSectionTarget.classList.toggle('d-none', !showDateDone);
    this.dateStartFieldTarget.disabled = !showDateStarted
    this.dateDoneFieldTarget.disabled = !showDateDone

    if (this.hasDateDoneFieldTarget) {
      this.dateDoneFieldTarget.required = showDateDone
    }
  }
}
