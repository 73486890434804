import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ["input", "toggle"]

  connect() {
    if (this.hasInputTarget) this.select()
  }

  select() {
    const value    = this.inputTarget.value
    const required = this.data.has("required")
    this.toggleTargets.forEach(target =>{
      if (target.dataset.selectToggleValue == value) {
        target.classList.remove("d-none")
        if (required) {
          target.querySelectorAll("input, select").forEach(requiredInput => {
            requiredInput.required = true
            requiredInput.disabled = false
          })
        }
      } else {
        target.classList.add("d-none")
        if (required) {
          target.querySelectorAll("input, select").forEach(requiredInput => {
            requiredInput.required = false
            requiredInput.disabled = true
          })
        }
      }
    })
  }
}
