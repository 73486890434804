import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["confirmationField", "submit"]

  connect() {
    this.confirmationWordValue = this.data.get('confirmationWordValue')
  }

  checkInput() {
    const inputValue = this.confirmationFieldTarget.value
    const confirmationWord = this.confirmationWordValue
  
    if (inputValue === confirmationWord) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
