import { Controller } from 'stimulus'
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ['input', 'output', 'label']

  connect() {
    const fileName = this.data.get('file')
    if (fileName) {
      this.labelTarget.innerHTML = this.configureLabel(fileName)
    } 
  }

  showNewFile() {
    let fileField = this.inputTarget
    let iframe = this.outputTarget

    if (fileField.files && fileField.files[0]) {
      const upload = new DirectUpload(fileField.files[0], '/rails/active_storage/direct_uploads', this)

      upload.create((error, blob) => {
        if (error) {
          console.error("Error")
        } 
      })

      iframe.style.display = 'block'
      iframe.src = window.URL.createObjectURL(fileField.files[0])
      iframe.height         = '820'
      iframe.width          = '700'
      this.labelTarget.innerHTML = this.configureLabel(fileField.files[0].name)
    }
  }

  configureLabel(filename) {
    return `<i class="far fa-file-alt"></i> ${filename} <div>${I18n.t('purchase_invoices.change_document')}</div>`
  }
}
